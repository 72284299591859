import React from "react"
import styled from "@emotion/styled"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import PageTemplate from "@shockventures/gatsby-theme-common/src/templates/PageTemplate"
import Portfolio from '@shockventures/gatsby-theme-common/src/components/Portfolio'
import siteContext from "../assets/siteContext"
import Bio from "../components/ArtistPage/Bio";
import News from "@shockventures/gatsby-theme-common/src/components/News/News";
import PageSection from "@shockventures/gatsby-theme-common/src/components/PageSection";
import {rawArticleSerializer} from "@shockventures/gatsby-theme-common/src/sections/NewsSection";

export const query = graphql`
    query ($id: String) {
        artist: sanityArtist(_id: {eq: $id}) {
            ...Artist
        }
    }
`
/**
 * This is not only used as an anchor but also as an offset from the top due to the fixed navigation bar
 */
const Anchor = styled.a`
    display: block;
    position: relative;
    top: -110px;
    visibility: hidden;
`
const Title = styled.h1`
    font-family: ${props => props.theme.headerFont};
    //font-size: 4rem;
    text-transform: uppercase;
    text-align: center;
`
export default (props) => {
  let data = props.data
  console.log(data)
  if(!data.artist.name) {
    return <div>No Artist</div>
  }
  data.page = {
    title: data.artist.name,
  }
  return (
      <PageTemplate {...props} siteContext={siteContext}>
        <Layout siteContext={siteContext} data={data}>
          <Title>{data.artist.name}</Title>
          <PageSection type={'portfolio'}>
            <Anchor id="portfolio-page-section">&nbsp;</Anchor>
            <Portfolio portfolio={data.artist.portfolio} gutter={12}/>
          </PageSection>
          <PageSection type={'bio'}>
            <Anchor id="bio-page-section">&nbsp;</Anchor>
            <Bio id="bio" artist={data.artist}/>
          </PageSection>
          <PageSection type={'news'}>
            <Anchor id="news-page-section">&nbsp;</Anchor>
            <News title={`${data.artist.name} News`}>{rawArticleSerializer(data.artist._rawArticles)}</News>
          </PageSection>
        </Layout>
      </PageTemplate>
  )
}
