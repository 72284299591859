import React from "react"
import styled from "@emotion/styled"
import {Container, Row, Col} from "@shockventures/gatsby-theme-common/src/components/Grid"
import BlockContent from "@shockventures/gatsby-theme-common/src/components/BlockContent";
import Link from "@shockventures/gatsby-theme-common/src/components/Link";
import SocialMediaLinks from "@shockventures/gatsby-theme-common/src/components/SocialMediaLinks"

const Bio = styled.section`
    // color: $color-text;
    max-width: 700px;
    margin: 10vh auto;
`
const Title = styled.h2`
    font-family: ${props => props.theme.headerFont};
    //font-size: 2rem;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
`
const Body = styled.div`
    margin: 24px 0;
    font-family: ${props => props.theme.bodyFont};
    //font-size: 1.8rem;
    font-style: italic;
    text-align: justify;
    line-height: 2;
    div {
        text-align: center;
    }
`
const Website = styled.div`
    text-align: center;
    line-height: 2;
    a, a:hover, a:visited {
        font-family: ${props => props.theme.headerFont};
        //font-size: 1.6rem;
        text-transform: lowercase;
        text-decoration: underline;
        color:${props => props.theme.textColor};
    }
`
export default ({artist}) => {
  /**
   * @todo refactor into CMS
   */
  let socialLinks = artist.socialLinks.map(socialLink => {
    switch(socialLink.service) {
      case 'instagram':
        return {
          screenname: 'Instagram',
          profileUrl: socialLink.url
        }
    }
  })
  return (
      <Bio>
        <Container fluid>
          <Row>
            <Col>
              <Title>Bio</Title>
              <Body><BlockContent blocks={artist._rawShortBio}/></Body>
              {artist.website &&
              <Website><Link to={artist.website.url}>{artist.website.title}</Link></Website>
              }
              {artist.agency &&
              <Website><Link to={artist.agency.website.url}>{artist.agency.website.title}</Link></Website>
              }
              <SocialMediaLinks connectedAccounts={socialLinks}/>
            </Col>
          </Row>
        </Container>
      </Bio>
  )
}